








import { Component } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// components
import ManagerOrderForm from '@/components/forms/order/ManagerOrderForm.vue'
// store
import ManagerOrdersModule from '@/store/modules/manager/orders'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    ManagerOrderForm,
  },
})
export default class Item extends NotifyMixin {
  private get orderID() {
    return +this.$route.params.id
  }

  private get order() {
    return ManagerOrdersModule.order
  }

  private mounted() {
    ManagerOrdersModule.fetchOrder(this.orderID)
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: `Заказ #${this.order?.id}`,
    }
  }
}
